<template>
  <div class="bg-white h-full flex justify-center">
    <div class="w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="0"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <div class="text-2xl text-bb-text-headers font-medium">Connect Your Google Ads Account</div>
        <div class="text-bb-text-secondary pb-6 px-6">
          <p>
            Link your account to start optimizing your campaigns right away. Choose one account now, you can add more
            later
          </p>
        </div>
      </div>

      <form class="w-500 mx-auto pb-20">
        <div>
          <!--GOOGLE ADS SEARCH-->
          <channel-card
            google-ads
            class="rounded-lg card-shadow"
            :is-active="googleAds"
            :is-onboarding="true"
            :has-connected-account="hasAConnectedAccount"
            @connected="onConnectedAccount"
            @select-channel="selectChannel('googleAds')"
          >
            <template #icon>
              <img
                src="/content/images/g-ads-icon.svg"
                alt="G_ADS_SEARCH"
              />
            </template>
            <template #card-title> Google Ads </template>
            <template #card-description> Connect to your Google Ads account </template>
          </channel-card>
        </div>

        <!--BUTTONS-->
        <div class="w-390 mx-auto">
          <div class="flex justify-between mt-10">
            <MergeButtonRound
              button-type="secondary"
              @click="$emit('previous')"
            >
              <template #left-icon>
                <IcChevronLeft />
              </template>
              Previous
            </MergeButtonRound>
            <MergeButtonRound
              :disabled="!hasAConnectedAccount"
              type="submit"
              @click="submit"
            >
              <template #right-icon>
                <IcChevronRight />
              </template>
              Next
            </MergeButtonRound>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import ChannelCard from '@/components/onboard-components-v2/ChannelCard'

export default {
  name: 'SetupConnections',
  components: {
    IcChevronLeft,
    IcChevronRight,
    HorizontalStepper,
    ChannelCard,
  },
  provide() {
    return {
      context: this.context,
    }
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amazon: false,
      googleAds: false,
      googleMerchantCenter: false,
      steps: ['Connect', 'Account Set Up', 'Business Details', 'Targets'],
      activeStepIndex: 0,
      hasAvailableGoogleStore: false,
      isLoading: false,
      hasAConnectedAccount: false,
      selectedGoogleAdsStore: null,
      accountList: [],
    }
  },
  mounted() {
    if (this.context.integration) {
      this.onConnectedAccount(this.context.integration, this.context.accountList)
    }
  },
  methods: {
    selectChannel(channel) {
      // this will close the channel when it is open.
      if (this[channel]) {
        this[channel] = !this[channel]
        return
      }

      this.amazon = false
      this.googleAds = false
      this.googleMerchantCenter = false

      this[channel] = true
    },
    onConnectedAccount(googleAdsStore, accountList) {
      if (googleAdsStore) {
        this.hasAConnectedAccount = true
        this.selectedGoogleAdsStore = googleAdsStore
        this.accountList = accountList
        this.googleAds = true
      }
    },
    submit() {
      if (this.hasAConnectedAccount && this.selectedGoogleAdsStore) {
        const payload = {
          googleAdsStore: this.selectedGoogleAdsStore,
          accountList: this.accountList,
        }
        this.$emit('next', payload)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card-shadow {
  box-shadow: 0px 2px 14px 0px rgba(136, 152, 170, 0.15);
}
</style>
