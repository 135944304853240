<template>
  <div>
    <search-input
      v-if="!showAddOptionInput"
      v-model="innerValue"
      input-id="option"
      input-name="option"
      :label="label"
      :tooltip="!!tooltip"
      :tooltip-text="tooltip"
      :sub-label="subLabel"
      :placeholder="innerValue ? innerValue.label : placeholder.search"
      :options="options"
      :error-message="$v.innerValue.$error ? errorMessage : null"
      @blur="$emit('blur')"
      @select-item="onSelectItem"
      @select-custom-option="onSelectCustomOption"
    >
      <template #custom-option="{ input, emitCustomOption }">
        <div
          class="text-bb-brand-purple text-sm flex items-center px-3 py-2 cursor-pointer"
          @click="emitCustomOption(input)"
        >
          <ic-plus size="16" />
          {{ placeholder.add }}
        </div>
      </template>
    </search-input>
    <text-input
      v-else
      ref="newOptionInput"
      v-model="newOption"
      :sub-label="subLabel"
      :tooltip="!!tooltip"
      tooltip-direction="bottom-start"
      input-type="text"
      input-name="newOption"
      input-id="newOption"
      :placeholder="placeholder.input"
      :error-message="$v.newOption.$error ? errorMessage : null"
      @blur="$emit('blur-new')"
    >
      <template #label>
        <div
          class="font-medium mb-3 mr-1"
          style="padding-top: 1px"
        >
          {{ label }}
        </div>
      </template>
      <template #suffix>
        <div class="h-10 w-20 absolute right-0 top-0 p-2">
          <div class="flex items-center justify-center gap-2">
            <ic-check
              :size="24"
              class="my-auto text-bb-dull-green cursor-pointer shadow-drop-12 rounded bg-white"
              @click="addOption"
            ></ic-check>
            <ic-close
              :size="24"
              class="my-auto text-bb-dull-red cursor-pointer shadow-drop-12 rounded bg-white"
              @click="hideAddOptionInput"
            ></ic-close>
          </div>
        </div>
      </template>
      <template #tooltip-content>
        <span class="font-medium">
          {{ tooltip }}
        </span>
      </template>
    </text-input>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import SearchInput from '@/components/input/brightbid/SearchInput'
import TextInput from '@/components/input/brightbid/TextInput'
import IcPlus from '@/components/icon/ic-plus'
import IcCheck from 'vue-material-design-icons/Check'
import IcClose from 'vue-material-design-icons/Close'

export default {
  name: 'CustomOptionDropdown',
  components: {
    SearchInput,
    TextInput,
    IcPlus,
    IcCheck,
    IcClose,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Select Option',
    },
    subLabel: {
      type: String,
      default: 'Select an option',
    },
    tooltip: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: 'Please select an option',
    },
    placeholder: {
      type: Object,
      default: () => ({
        search: 'Search...',
        input: 'Please type...',
        add: 'Add New Option',
      }),
    },
  },
  data() {
    return {
      showAddOptionInput: false,
      newOption: '',
      selectedOption: null,
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  validations() {
    return {
      innerValue: { required },
      newOption: { required },
    }
  },
  methods: {
    addOption() {
      this.$v.newOption.$touch()
      if (this.$v.newOption.$invalid) return

      const newOption = {
        label: this.newOption,
        value: null,
      }
      this.innerValue = newOption
      this.$emit('add', newOption)
      this.showAddOptionInput = false
      this.newOption = ''
    },
    hideAddOptionInput() {
      this.innerValue = this.selectedOption
      this.showAddOptionInput = false
      this.newOption = ''
    },
    onSelectCustomOption() {
      this.showAddOptionInput = true
      this.selectedOption = this.innerValue
      this.$nextTick(() => {
        if (this.$refs.newOptionInput) {
          this.$refs.newOptionInput.focus()
        }
      })
    },
    onSelectItem(selectedItem) {
      this.$emit('select-item', selectedItem)
    },
    validateCustomOptionDropdown() {
      this.$v.innerValue.$touch()
      return !this.$v.innerValue.$invalid
    },
  },
}
</script>
